import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

// layout constants
import { LayoutTypes } from '../constants/layout';

// store
import { RootState } from '../redux/store';

// All layouts containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal/';
import TwoColumnLayout from '../layouts/TwoColumn/';

import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from './index';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';

interface RoutesProps { }

const Routes = (props: RoutesProps) => {
    const { layout, user } = useSelector((state: RootState) => ({
        layout: state.Layout,
        user: state.Auth.user,
    }));

    // const { accounts } = useMsal();
    // const account = accounts[0];
    // const tenantId = account?.tenantId
    // const idPattern = new RegExp('[A-Fa-f0-9]{8}-([A-Fa-f0-9]{4}-){3}[A-Fa-f0-9]{12}');
    // if (user && !idPattern.test(tenantId)) {
    //     return <div></div>
    // }
    // if (!user && idPattern.test(tenantId)) {
    //     return <div></div>
    // }

    const getLayout = () => {
        let layoutCls = TwoColumnLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_VERTICAL:
                layoutCls = VerticalLayout;
                break;
            default:
                layoutCls = TwoColumnLayout;
                break;
        }
        return layoutCls;
    };

    let Layout = getLayout();

    return (
        <>
            <UnauthenticatedTemplate>
                <BrowserRouter>
                    <Switch>

                        <Route path={publicProtectedFlattenRoutes.map((r: any) => r['path'])}>
                            <DefaultLayout {...props} layout={layout}>
                                <Switch>
                                    {publicProtectedFlattenRoutes.map((route: any, index: number) => {
                                        return (
                                            !route.children && (
                                                <route.route
                                                    key={index}
                                                    path={route.path}
                                                    roles={route.roles}
                                                    exact={route.exact}
                                                    component={route.component}
                                                />
                                            )
                                        );
                                    })}
                                </Switch>
                            </DefaultLayout>
                        </Route>
                        <Route path={authProtectedFlattenRoutes.map((r: any) => r['path'])}>
                            <Layout {...props}>
                                <Switch>
                                    {authProtectedFlattenRoutes.map((route: any, index: number) => {
                                        return (
                                            !route.children && (
                                                <route.route
                                                    key={index}
                                                    path={route.path}
                                                    roles={route.roles}
                                                    exact={route.exact}
                                                    component={route.component}
                                                />
                                            )
                                        );
                                    })}
                                </Switch>
                            </Layout>
                        </Route>

                    </Switch>
                </BrowserRouter>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <BrowserRouter>
                    <Switch>

                        <Route path={publicProtectedFlattenRoutes.map((r: any) => r['path'])}>
                            <DefaultLayout {...props} layout={layout}>
                                <Switch>
                                    {publicProtectedFlattenRoutes.map((route: any, index: number) => {
                                        return (
                                            !route.children && (
                                                <route.route
                                                    key={index}
                                                    path={route.path}
                                                    roles={route.roles}
                                                    exact={route.exact}
                                                    component={route.component}
                                                />
                                            )
                                        );
                                    })}
                                </Switch>
                            </DefaultLayout>
                        </Route>
                        <Route path={authProtectedFlattenRoutes.map((r: any) => r['path'])}>
                            <Layout {...props}>
                                <Switch>
                                    {authProtectedFlattenRoutes.map((route: any, index: number) => {
                                        return (
                                            !route.children && (
                                                <route.route
                                                    key={index}
                                                    path={route.path}
                                                    roles={route.roles}
                                                    exact={route.exact}
                                                    component={route.component}
                                                />
                                            )
                                        );
                                    })}
                                </Switch>
                            </Layout>
                        </Route>

                    </Switch>
                </BrowserRouter>
            </AuthenticatedTemplate>

        </>
    );
};

export default Routes;
