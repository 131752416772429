import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
import { APICore, setAuthorization } from '../../helpers/api/apiCore';

// helpers
import {
    login as loginApi,
    logout as logoutApi,
    signup as signupApi,
    forgotPassword as forgotPasswordApi,
} from '../../helpers/';

// actions
import { authApiResponseSuccess, authApiResponseError } from './actions';

// constants
import { AuthActionTypes } from './constants';

interface UserData {
    payload: {
        username: string;
        password: string;
        fullname: string;
        email: string;
    };
    type: string;
}

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password }, type }: UserData): SagaIterator {
    try {
        // Todo: Login the user
        const response = yield call(loginApi, { username, password });
        const user = response.data;
        // const user = {
        //     homeAccountId: '36b5c420-9681-455d-8e1f-efaf49170441.0677c922-24fc-4b99-9704-a15f5258d597',
        //     environment: 'login.windows.net',
        //     tenantId: '0677c922-24fc-4b99-9704-a15f5258d597',
        //     username: 'ridwannul.malik@pounce.fi',
        //     localAccountId: '36b5c420-9681-455d-8e1f-efaf49170441',
        //     name: 'Ridwannul Malik',
        //     idTokenClaims: {
        //         aud: '15b1170c-cbb5-47b6-93a1-db97bba7684c',
        //         iss: 'https://login.microsoftonline.com/0677c922-24fc-4b99-9704-a15f5258d597/v2.0',
        //         iat: 1652180389,
        //         nbf: 1652180389,
        //         exp: 1652184289,
        //         aio: 'ATQAy/8TAAAATuAV/GC4IpnOTgVNp/nwFQrp3ihk8bggRS+/FTqNQXXxDlIP8ERjE78DzK0g++Eg',
        //         name: 'Ridwannul Malik',
        //         nonce: '1a003161-efe8-4b3b-a0aa-21618438e908',
        //         oid: '36b5c420-9681-455d-8e1f-efaf49170441',
        //         preferred_username: 'ridwannul.malik@pounce.fi',
        //         rh: '0.AXkAIsl3BvwkmUuXBKFfUljVlwwXsRW1y7ZHk6Hbl7unaEx5AIk.',
        //         sub: 'la3YJhgQiT8xqoTOYitAIxec-mFNBkJ3drhF_bmgU3o',
        //         tid: '0677c922-24fc-4b99-9704-a15f5258d597',
        //         uti: 'kcDw6ogJXkiM7wiEk-WjAA',
        //         ver: '2.0',
        //     },
        // };
        // NOTE - You can change this according to response format from your api
        api.setLoggedInUser(user);
        setAuthorization(user['token']);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
    try {
        yield call(logoutApi);
        api.setLoggedInUser(null);
        setAuthorization(null);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

function* signup({ payload: { fullname, email, password } }: UserData): SagaIterator {
    try {
        const response = yield call(signupApi, { fullname, email, password });
        const user = response.data;
        // api.setLoggedInUser(user);
        // setAuthorization(user['token']);
        yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* forgotPassword({ payload: { username } }: UserData): SagaIterator {
    try {
        const response = yield call(forgotPasswordApi, { username });
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    }
}
export function* watchLoginUser() {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogout), fork(watchSignup), fork(watchForgotPassword)]);
}

export default authSaga;
